/* ImmersiveAudioRecorder.css */

/* Main overlay that covers the whole screen */
.immersive-audio-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Container for the modal content */
.immersive-audio-container {
  width: 100%;
  max-width: 500px;
  background-color: #1a1b26;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  animation: scaleIn 0.2s ease-out;
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

/* Companion header with avatar and info */
.companion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.companion-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.companion-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgba(162, 87, 127, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  box-shadow: 0 0 10px rgba(162, 87, 127, 0.3);
  animation: pulse 2s infinite;
}

.companion-avatar img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(162, 87, 127, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(162, 87, 127, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(162, 87, 127, 0);
  }
}

.companion-info h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: white;
}

.companion-info .status {
  margin: 0;
  font-size: 0.875rem;
  color: rgba(162, 87, 127, 0.9);
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.companion-info .status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(162, 87, 127, 0.9);
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
}

.recording-duration {
  font-family: monospace;
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.8);
  padding: 0.375rem 0.75rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

/* Visualization area */
.visualization-area {
  flex: 1;
  width: 100%;
  position: relative;
  aspect-ratio: 16/9;
  max-height: 60vh;
  background-color: #1a1a2e;
}

.visualization-area canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  color: #ff6b6b;
  text-align: center;
  max-width: 85%;
  font-size: 0.875rem;
  border: 1px solid rgba(255, 107, 107, 0.3);
}

/* Processing overlay and animation - NEW */
.processing-overlay {
  position: absolute;
  inset: 0;
  background: rgba(26, 26, 46, 0.85);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-out;
}

.processing-animation {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.processing-animation .dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #a2577f;
  opacity: 0.8;
}

.processing-animation .dot1 {
  animation: dotPulse 1.4s infinite ease-in-out;
}

.processing-animation .dot2 {
  animation: dotPulse 1.4s infinite ease-in-out;
  animation-delay: 0.2s;
}

.processing-animation .dot3 {
  animation: dotPulse 1.4s infinite ease-in-out;
  animation-delay: 0.4s;
}

@keyframes dotPulse {
  0%, 100% { 
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% { 
    transform: scale(1.2);
    opacity: 1;
    box-shadow: 0 0 12px rgba(162, 87, 127, 0.8);
  }
}

.processing-overlay p {
  color: white;
  font-size: 1rem;
  margin: 0;
  text-align: center;
  max-width: 80%;
  animation: fadeInUp 0.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Button controls */
.controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.cancel-button, .done-button {
  padding: 0.75rem 1.25rem;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
}

.cancel-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.cancel-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.done-button {
  background-color: rgba(162, 87, 127, 0.2);
  color: rgb(231, 165, 195);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.done-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    rgba(162, 87, 127, 0) 0%,
    rgba(162, 87, 127, 0.2) 50%,
    rgba(162, 87, 127, 0) 100%
  );
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.done-button:hover {
  background-color: rgba(162, 87, 127, 0.3);
}

.done-button.processing {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: rgba(162, 87, 127, 0.15);
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .immersive-audio-overlay {
    padding: 0.5rem;
  }
  
  .immersive-audio-container {
    max-height: 85vh;
    border-radius: 12px;
  }
  
  .companion-header {
    padding: 0.75rem;
  }
  
  .companion-avatar {
    width: 36px;
    height: 36px;
    font-size: 1.25rem;
  }
  
  .companion-info h3 {
    font-size: 1rem;
  }
  
  .companion-info .status {
    font-size: 0.75rem;
  }
  
  .recording-duration {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
  
  .controls {
    padding: 0.75rem;
  }
  
  .cancel-button, .done-button {
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    border-radius: 8px;
  }
  
  /* Ensure buttons have enough space between them */
  .cancel-button {
    margin-right: 0.5rem;
  }
  
  /* Make visualization area smaller on mobile for better layout */
  .visualization-area {
    aspect-ratio: 3/4;
  }
  
  /* Ensure text is readable on small screens */
  .error-message {
    font-size: 0.75rem;
    padding: 0.75rem;
  }
  
  /* Adjust processing animation for mobile */
  .processing-animation .dot {
    width: 12px;
    height: 12px;
  }
  
  .processing-overlay p {
    font-size: 0.875rem;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .immersive-audio-container {
    max-height: -webkit-fill-available;
  }
  
  /* Fix border radius rendering */
  .immersive-audio-container {
    border-radius: 12px;
    -webkit-border-radius: 12px;
  }
  
  /* Improve tap targets */
  .cancel-button, .done-button {
    min-height: 44px;
  }
}

/* Make sure that the modal works correctly in landscape mode */
@media (max-height: 500px) and (orientation: landscape) {
  .immersive-audio-container {
    flex-direction: row;
    max-height: 90vh;
    max-width: 90vw;
  }
  
  .visualization-area {
    flex: 2;
    aspect-ratio: auto;
    max-height: none;
    height: 100%;
  }
  
  .companion-header {
    flex-direction: column;
    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 100%;
    padding: 1rem;
    justify-content: flex-start;
    gap: 1rem;
    width: 180px;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: none;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px 0 0 0;
    padding: 0.5rem;
  }
} 
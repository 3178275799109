.tooltipContainer {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1000;
  transition: all 0.3s ease;
}

.connector {
  width: 2px;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent);
  margin-bottom: 4px;
  transition: height 0.3s ease;
}

.infoIcon {
  background: rgba(17, 24, 39, 0.95);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #e5e7eb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  transform-origin: center bottom;
}

.infoIcon:hover {
  transform: scale(1.1);
  color: white;
}

.expandedTooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(17, 24, 39, 0.95);
  border-radius: 12px;
  padding: 20px;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  width: min(90vw, 400px);
  max-height: 90vh;
  overflow-y: auto;
  animation: expandIn 0.3s ease-out;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #f3f4f6;
}

.instructions {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 16px;
  color: #e5e7eb;
}

.example {
  font-size: 0.95rem;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  color: #d1d5db;
  font-style: italic;
}

.closeButton {
  background: linear-gradient(to right, #fed7aa, #fcd1d1);
  color: #1a1a1a;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.closeButton:hover {
  background: linear-gradient(to right, #fcd1d1, #fed7aa);
  transform: translateY(-1px);
}

@keyframes expandIn {
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 640px) {
  .expandedTooltip {
    width: 90vw;
    max-height: 80vh;
    padding: 16px;
  }
  
  .title {
    font-size: 1.1rem;
  }
  
  .instructions {
    font-size: 0.95rem;
  }
  
  .example {
    font-size: 0.9rem;
    padding: 12px;
  }
} 
/* TulpaManager.css */
.fixed {
  animation: fadeIn 0.2s ease-out;
  /* Ensure proper stacking and visibility on all devices */
  z-index: 9999;
  display: flex !important;
  opacity: 1 !important;
  visibility: visible !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Force component display on all devices */
.fixed.inset-0 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex !important;
}

.animate-fadeIn {
  animation: fadeIn 0.2s ease-out forwards;
}

/* Companion card hover effects */
button {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Disable hover transform on mobile devices to avoid stuck hover states */
@media (hover: hover) {
  button:hover:not(:disabled) {
    transform: translateY(-2px) scale(1.02);
  }
}

/* Active state for buttons to provide feedback on tap */
button:active:not(:disabled) {
  transform: scale(0.98);
}

/* Level badge glow effect */
.absolute {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px rgba(255, 148, 148, 0.3);
  }
  to {
    box-shadow: 0 0 10px rgba(255, 148, 148, 0.4);
  }
}

/* Icon hover animation */
.text-5xl, img.transform {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (hover: hover) {
  .group:hover .text-5xl, .group:hover img.transform {
    transform: scale(1.15) rotate(5deg);
  }
}

/* Trait tags hover effect */
.bg-gray-700 {
  transition: all 0.2s ease;
}

.bg-gray-700:hover {
  background-color: rgba(236, 72, 153, 0.2);
  color: rgba(236, 72, 153, 0.9);
}

/* Custom scrollbar */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 148, 148, 0.3) rgba(26, 27, 38, 0.5);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: rgba(26, 27, 38, 0.5);
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(255, 148, 148, 0.3);
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 148, 148, 0.5);
}

/* Selection confirmation button pulse */
.bg-pink-500\/20 {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .7;
  }
}

/* Mobile-first responsive design */
.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

@media (min-width: 640px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Ensure trait tags don't get cut off on mobile */
.text-xs,
.text-\[10px\] {
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Improve card spacing on mobile */
.p-3\.bg-\[#2a2b36\]\.rounded-lg\.h-full {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

/* Make sure active indicator is visible */
.absolute\.top-2\.right-2\.px-1\.5\.py-0\.5\.sm\:px-2\.sm\:py-0\.5\.bg-\[#ff9494\]\/20\.text-\[#ff9494\]\.text-\[10px\]\.sm\:text-xs\.rounded-full {
  z-index: 10;
  white-space: nowrap;
  min-width: 50px;
  text-align: center;
}

/* Improve line clamping on mobile */
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
}

/* Mobile optimizations for companion card layout */
@media (max-width: 768px) {
  .grid {
    grid-gap: 0.75rem;
  }
  
  .p-3\.bg-\[#2a2b36\]\.rounded-lg\.h-full {
    padding: 0.75rem;
  }
  
  .mb-2.flex.items-center.w-full {
    margin-bottom: 0.5rem;
  }
  
  .text-gray-300.text-xs.mb-2.line-clamp-3 {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
  }
  
  .w-10.h-10.bg-\[\#1a1b26\].rounded-full {
    width: 2rem;
    height: 2rem;
  }
  
  .flex-1.min-w-0 {
    min-width: 0;
    max-width: calc(100% - 3rem);
  }
}

.companion-card.mystery {
  background: linear-gradient(45deg, #2a2b36, #32333e);
  border: 2px solid transparent;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.companion-card.mystery:hover {
  border-color: rgba(255, 148, 148, 0.5);
  transform: translateY(-2px);
}

.mystery-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: sparkle 2s infinite;
}

/* Ensure all cards have proper relative positioning for absolute elements */
button[aria-selected],
.companion-card {
  position: relative;
  overflow: hidden;
}

/* Fix selection indicators to avoid overlapping */
div.absolute.top-2.left-2.w-4.h-4.sm\:w-5.sm\:h-5.rounded-full.bg-\[\#ff9494\]\/30.flex.items-center.justify-center {
  z-index: 10;
  top: 8px;
  left: 8px;
  pointer-events: none;
}

/* Ensure active indicator is consistent across different selectors */
div.absolute.top-2.right-2.px-1\.5.py-0\.5.sm\:px-2.sm\:py-0\.5.bg-\[\#ff9494\]\/20.text-\[\#ff9494\].text-\[10px\].sm\:text-xs.rounded-full {
  right: 8px;
  top: 8px;
  min-width: 60px;
  text-align: center;
  z-index: 10;
  pointer-events: none;
}

/* At small sizes, ensure active and selection indicators don't compete for space */
@media (max-width: 480px) {
  div.absolute.top-2.left-2.w-4.h-4.sm\:w-5.sm\:h-5.rounded-full.bg-\[\#ff9494\]\/30.flex.items-center.justify-center {
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
  }
  
  div.absolute.top-2.left-2.w-4.h-4.sm\:w-5.sm\:h-5.rounded-full.bg-\[\#ff9494\]\/30.flex.items-center.justify-center > div {
    width: 6px;
    height: 6px;
  }
  
  div.absolute.top-2.right-2.px-1\.5.py-0\.5.sm\:px-2.sm\:py-0\.5.bg-\[\#ff9494\]\/20.text-\[\#ff9494\].text-\[10px\].sm\:text-xs.rounded-full {
    top: 5px;
    right: 5px;
    font-size: 0.65rem;
    padding: 2px 6px;
  }
}

/* Responsive fixes for Safari and iOS */
@supports (-webkit-touch-callout: none) {
  .fixed > div {
    max-height: -webkit-fill-available;
  }
  
  /* Fix for buttons on iOS */
  button {
    -webkit-tap-highlight-color: transparent;
  }
}

/* Animation for card entries */
@keyframes cardFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Individual card animations */
button.animate-fadeIn {
  animation: cardFadeIn 0.3s ease-out forwards;
  opacity: 0;
}

/* Selection indicator pulse */
.absolute.top-2.left-2 {
  animation: selectionPulse 2s infinite;
}

@keyframes selectionPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 148, 148, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 148, 148, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 148, 148, 0);
  }
}

/* Trait tag hover animations */
.group-hover\:bg-\[#ff9494\]\/10 {
  transition: all 0.2s ease;
}

/* Improved responsive design for better mobile experience */
@media (max-width: 480px) {
  button.animate-fadeIn {
    animation-duration: 0.2s; /* Faster animations on mobile */
  }
  
  .fixed > div {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 10px !important;
    height: 85vh !important;
  }
  
  .p-4 {
    padding: 0.75rem !important;
  }
  
  .text-base {
    font-size: 0.9rem !important;
  }
  
  .text-xs {
    font-size: 0.7rem !important;
  }
  
  /* Improve tappability */
  button {
    min-height: 44px !important;
  }
}

/* Optimal styling for iPhone */
@supports (-webkit-touch-callout: none) {
  .fixed > div {
    height: -webkit-fill-available !important;
    max-height: 85vh !important;
  }
  
  /* Fix for rounded corners on iOS */
  .rounded-xl {
    border-radius: 12px !important;
  }
}

/* Desktop specific fixes for modal positioning */
@media (min-width: 768px) {
  .fixed.inset-0 {
    overflow-y: auto;
  }
  
  .fixed.inset-0 > div {
    margin: auto;
    max-height: calc(100vh - 40px);
  }
}

/* Ensure favicon images render correctly */
img[src="/favicon.ico"] {
  object-fit: contain !important;
  width: 80% !important;
  height: 80% !important;
  border-radius: 0 !important;
  overflow: visible !important;
}

.flex-shrink-0.w-10.h-10.bg-\[\#1a1b26\].rounded-full img,
.w-16.h-16.bg-\[\#1a1b26\].rounded-full img,
.w-8.h-8.relative.flex.items-center.justify-center img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: inline-block;
}

/* Fix icon rendering on iOS */
@supports (-webkit-touch-callout: none) {
  img[src="/favicon.ico"] {
    transform: scale(0.8);
  }
}

/* Ensure proper spacing in mobile mode */
@media (max-width: 768px) {
  .text-gray-300.text-xs.mb-2.line-clamp-3 {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    max-height: 5em !important; /* Allow a bit more height on mobile */
  }
  
  .mb-2.flex.items-center.w-full {
    margin-bottom: 0.5rem;
    min-height: 2.5rem;
  }

  .mt-auto.w-full.flex.flex-wrap.gap-1.justify-center {
    margin-top: 0.25rem;
  }
  
  img[src="/favicon.ico"] {
    object-position: center;
  }
} 
/* CollapsibleThinking.css */
.thinking-content-wrapper {
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
  font-size: 0.95rem;
}

/* Dark theme support */
@media (prefers-color-scheme: dark) {
  .thinking-content-wrapper {
    background-color: rgba(30, 41, 59, 0.5);
    border-color: rgba(71, 85, 105, 0.5);
  }
}

/* Trigger styling */
.thinking-trigger {
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #475569;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.thinking-trigger:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.thinking-trigger.open {
  border-bottom: 1px solid #e2e8f0;
}

.trigger-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1;
  color: #64748b;
}

.trigger-text {
  font-weight: 500;
}

/* Content area styling */
.thinking-details {
  padding: 1rem;
  color: #334155;
  line-height: 1.6;
  overflow-x: auto;
}

.thinking-details p {
  margin-bottom: 0.75rem;
}

.thinking-details code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.9em;
}

.thinking-details pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 0.25rem;
  overflow-x: auto;
  margin: 1rem 0;
}

.thinking-details pre code {
  background-color: transparent;
  padding: 0;
}

/* For code blocks */
.thinking-details .hljs {
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 1rem;
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .thinking-content-wrapper {
    margin: 0.75rem 0;
  }
  
  .thinking-trigger {
    padding: 0.6rem 0.75rem;
    font-size: 0.85rem;
  }
  
  .thinking-details {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Agent-specific styling */
.talk-bubble.agent .thinking-content-wrapper {
  background-color: rgba(30, 41, 59, 0.4);
  border-color: rgba(71, 85, 105, 0.4);
}

.talk-bubble.agent .thinking-trigger {
  color: rgba(255, 255, 255, 0.8);
}

.talk-bubble.agent .thinking-trigger:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.talk-bubble.agent .thinking-details {
  color: rgba(255, 255, 255, 0.9);
}

.talk-bubble.agent .thinking-details code {
  background-color: rgba(0, 0, 0, 0.2);
}

.talk-bubble.agent .thinking-details pre {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Animation styles */
.thinking-collapsible {
  transition: all 0.3s ease-in-out;
}

/* Override react-collapsible default styles */
.Collapsible {
  width: 100%;
}

.Collapsible__contentInner {
  padding: 0;
}

.collapsible-thinking-wrapper {
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.collapsible-thinking-container {
  width: 100%;
  position: relative;
}

.collapsible-thinking-trigger {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  outline: none;
  position: relative;
  z-index: 1;
}

.collapsible-thinking-trigger:hover {
  background: rgba(0, 0, 0, 0.3);
}

.collapsible-thinking-trigger:focus-visible {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.collapsible-thinking-trigger.streaming {
  background: rgba(59, 130, 246, 0.2);
}

.thinking-icon {
  font-size: 1.25rem;
  transition: transform 0.2s ease;
}

.thinking-expand-icon {
  margin-left: auto;
  font-size: 1.25rem;
  font-weight: 300;
  transition: transform 0.2s ease;
}

.collapsible-thinking-content {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.15);
}

.thinking-content-inner {
  padding: 1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 0.95rem;
}

.thinking-content-inner p {
  margin-bottom: 0.75rem;
}

.thinking-content-inner code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.thinking-content-inner pre {
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 0.25rem;
  overflow-x: auto;
  margin: 1rem 0;
}

.thinking-content-inner pre code {
  background: transparent;
  padding: 0;
}

.thinking-content-inner .inline-code {
  background: rgba(0, 0, 0, 0.2);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.9em;
}

/* Streaming animation */
.thinking-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

/* Dark theme support */
@media (prefers-color-scheme: dark) {
  .collapsible-thinking-wrapper {
    background: rgba(255, 255, 255, 0.05);
  }
  
  .collapsible-thinking-trigger {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .collapsible-thinking-trigger:hover {
    background: rgba(255, 255, 255, 0.15);
  }
  
  .thinking-content-inner {
    color: rgba(255, 255, 255, 0.9);
  }
  
  .thinking-content-inner code,
  .thinking-content-inner pre {
    background: rgba(255, 255, 255, 0.1);
  }
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .collapsible-thinking-trigger {
    padding: 0.6rem 0.75rem;
    font-size: 0.85rem;
  }
  
  .thinking-content-inner {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
  
  .thinking-icon {
    font-size: 1.1rem;
  }
  
  .thinking-expand-icon {
    font-size: 1.1rem;
  }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .collapsible-thinking-wrapper {
    border: 1px solid ButtonText;
  }
  
  .collapsible-thinking-trigger {
    background: ButtonFace;
    color: ButtonText;
  }
  
  .thinking-content-inner {
    color: ButtonText;
  }
  
  .thinking-content-inner code,
  .thinking-content-inner pre {
    border: 1px solid ButtonText;
  }
}

/* Modal styling */
.thinking-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.thinking-modal {
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.thinking-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #252525;
}

.thinking-modal-header h3 {
  margin: 0;
  color: white;
}

.thinking-modal-close {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.thinking-modal-close:hover {
  color: white;
}

.thinking-modal-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
}

.thinking-modal-content pre {
  background-color: #2d2d2d;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}

.thinking-modal-content code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 0.9em;
  background-color: rgba(100, 100, 100, 0.2);
  padding: 3px 5px;
  border-radius: 3px;
}

.thinking-content-inner pre code {
  background-color: transparent;
  padding: 0;
}

/* Make thinking components more prominent */
.thinking-components-container {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Show a small marker when collapsed for thinking components */
.collapsible-thinking-container:not(.expanded)::before {
  content: '';
  display: block;
  width: 3px;
  height: 100%;
  background-color: #64b5f6;
  position: absolute;
  left: 0;
  top: 0;
}

/* Mobile responsiveness */
@media (max-width: 640px) {
  .thinking-modal {
    width: 95%;
    max-height: 80vh;
  }
  
  .thinking-modal-header {
    padding: 12px;
  }
  
  .thinking-modal-content {
    padding: 12px;
    font-size: 0.9rem;
  }
  
  .collapsible-thinking-trigger {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
  
  .thinking-content-inner {
    padding: 12px;
    font-size: 0.85rem;
  }
} 
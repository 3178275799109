/* ==========================================================
   ChatModal CSS - Comprehensive Dark-Themed Styles with Gradient Colors
   ========================================================== */

/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Varela+Round');

/* ============= CSS Reset for Consistency ============= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', 'Varela Round', sans-serif;
  font-size: 16px;
  background-color: var(--background-primary, #121212);
  color: var(--text-primary, #ffffff);
  overflow-x: hidden;
  /* Prevent scroll chaining at document level */
  overscroll-behavior: none;
}

/* ============= Root Variables ============= */
:root {
  --text-primary: #ffffff;
  --text-secondary: #a6aaae;
  --text-tertiary: #6b6f73;
  --background-primary: #121212;
  --background-secondary: #232323;
  --background-soft: #1e1e1e;
  --background-unified: #1a1b27; /* New unified background color */
  --border-primary: #3a3a3a;
  --gradient-primary: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  --gradient-user: linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85));
  --shadow-primary: 0px 6px 16px 0px #00000050, 0px 4px 14px 0px #00000040, 0px 8px 10px 0px #00000030;
  --border-radius-default: 4px;
  --padding-default: 0.5rem;
  --font-size-default: 1rem;
  --vh: 1vh; /* Default viewport height variable */
  --visible-height: 100vh; /* Variable for handling virtual keyboard */
}

/* ============= ChatModal Container Styles ============= */
.chat-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* Use custom viewport height to handle virtual keyboard */
  height: calc(var(--visible-height, 100vh));
  position: relative;
  overflow: hidden;
  background-color: var(--background-unified);
  transition: all 0.3s ease;
}

/* ============= ChatBot Section Styles ============= */
.chatbot-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Reduced gap to make it appear more unified */
  padding: 0.5rem 0.75rem 3rem;
  width: 100%; 
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  contain: layout size;
  border-radius: 12px;
  background-color: var(--background-unified); /* Changed to unified background */
  box-shadow: none; /* Removed shadow within the chat section */
  margin: 0 auto;
  padding-bottom: env(safe-area-inset-bottom);
}

/* Desktop styles for boxed layout */
@media (min-width: 768px) {
  .chat-modal-container {
    padding: 1.5rem;
    background-color: var(--background-primary);
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle at center, rgba(40, 40, 40, 0.6) 0%, rgba(20, 20, 20, 0.8) 100%);
  }
  
  .chatbot-section {
    height: calc(100vh - 3rem);
    max-height: 800px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 1rem;
    box-shadow: 
      0 10px 25px -5px rgba(0, 0, 0, 0.5),
      0 5px 10px -5px rgba(0, 0, 0, 0.5),
      0 0 0 1px rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--background-unified); /* Changed to unified background */
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* Improved scroll aesthetics for desktop */
  .native-scroll-container::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  
  .native-scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 148, 148, 0.3);
    border-radius: 4px;
  }
  
  .native-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 148, 148, 0.5);
  }
}

/* Large desktop styles */
@media (min-width: 1200px) {
  .chat-modal-container {
    padding: 2rem;
  }
  
  .chatbot-section {
    max-width: 900px;
    max-height: 85vh;
  }
}

/* New native-like scrolling container styles */
.native-scroll-container {
  flex: 1;
  position: relative;
  overflow-y: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-overscroll-behavior: none;
  touch-action: pan-y;
  outline: none;
  will-change: scroll-position;
  height: 100%;
  padding: 1rem 0;
  margin-bottom: 1rem; /* Reduced space for input */
}

.chat-messages {
  flex: 1;
  overflow-y: auto; /* Allow scrolling only in the messages area */
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
  padding-top: 0.5rem;
  /* Add mask for fading out content at top and bottom */
  mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 95%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 95%, transparent 100%);
  /* Set a fixed height to ensure it doesn't overflow */
  height: calc(100vh - var(--header-height) - var(--footer-height) - 7rem); /* Account for padding and input */
  max-height: calc(100vh - var(--header-height) - var(--footer-height) - 7rem);
}

.chatbot-header, 
.chatbot-section > div:first-child {
  background-color: var(--background-unified) !important; /* Force consistent background */
  border-bottom: none; /* Remove separator line */
  padding: 0.75rem 1rem;
  margin-bottom: 0;
}

.chatbot-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gradient-primary);
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Chat Messages */
.chat-messages-container {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
  padding-right: 0.5rem;
}

.talk-bubble {
  display: inline-block;
  position: relative;
  color: var(--text-primary);
  background: var(--gradient-primary);
  border-radius: 10px;
  padding: 10px;
  word-wrap: break-word;
  margin-bottom: 0.5rem;
}

.talk-bubble.agent {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  color: var(--text-primary);
}

.talk-bubble.agent svg {
  opacity: 0.02;
}

.talk-bubble:not(.user) {
  border-bottom-left-radius: 0;
  float: left;
}

.talk-bubble.user {
  background: linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85));
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0;
  float: right;
  color: #1a1a1a;
  font-weight: 500;
  /* Remove the animation for a more minimal look */
  animation: none;
}

/* Input and Controls */
.chatbot-input-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  padding: 0.75rem 1rem;
  background: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto; /* Pushes the container to the bottom */
  width: 100%;
}

@media (min-width: 768px) {
  .chatbot-input-container {
    position: relative;
    background: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border-top: none;
    padding: 0.5rem 1rem;
  }
}

/* Input field */
.chatbot-input {
  background: rgba(42, 43, 54, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.2s ease;
}

.chatbot-input:focus {
  border-color: rgba(255, 105, 180, 0.5);
  box-shadow: 0 0 0 2px rgba(255, 105, 180, 0.1);
}

/* Ensure proper spacing on mobile */
@supports (padding: max(0px)) {
  .chatbot-input-container {
    padding-bottom: max(0.75rem, env(safe-area-inset-bottom));
  }
}

/* Prevent zoom on mobile */
@media (max-width: 767px) {
  .no-zoom-fix {
    font-size: 16px !important;
  }
}

.audio-recorder-wrapper {
  position: relative;
  z-index: 10;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  /* Remove margin-bottom here as it's controlled in media queries */
}

.audio-recorder-mobile {
  width: 48px !important;
  height: 48px !important;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  background: var(--gradient-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  /* Make pulse animation stronger and more visible */
  animation: subtle-pulse 2s infinite !important;
  background-size: 400% 400%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.audio-recorder-mobile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: var(--gradient-primary);
  background-size: 400% 400%;
  z-index: -1;
  animation: gradient-shift 15s ease infinite;
}

/* Ensure the button doesn't get stuck in active state on mobile */
.audio-recorder-mobile:active {
  transform: scale(0.95);
  background: var(--gradient-user);
}

/* Remove hover effects on mobile */
@media (hover: none) {
  .audio-recorder-mobile:hover {
    transform: none;
    background: var(--gradient-primary);
  }
}

/* Prevent body scrolling when in chat view */
body.in-chat-view {
  overscroll-behavior: none;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Ensure app layout covers full viewport */
.app-layout.chat-active {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* Specific mobile optimizations */
@media (max-width: 768px) {
  .chat-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    /* Use custom variable for height with keyboard open */
    height: calc(var(--visible-height, 100vh));
    padding: 0;
    background-color: var(--background-unified); /* Consistent background */
  }
  
  .chatbot-section {
    height: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding-bottom: calc(3rem + env(safe-area-inset-bottom, 0px));
    background-color: var(--background-unified); /* Consistent background */
    /* Ensure the section doesn't extend beyond the visible viewport */
    max-height: calc(var(--visible-height, 100vh));
  }
  
  /* Center the audio recorder on desktop */
  .audio-recorder-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
  }
  
  /* Position the input container properly on desktop */
  .chatbot-input-container {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 0.5rem;
    z-index: 50;
    background-color: var(--background-unified); /* Match main background */
    backdrop-filter: blur(8px);
  }
  
  /* Improved chat message spacing on mobile */
  .native-scroll-container {
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    max-height: calc(100vh - 160px); /* Account for header, input, and bottom controls */
  }
  
  /* Position prompt suggestions at the bottom */
  .prompt-suggestions-row {
    margin-bottom: 0.25rem;
    position: absolute;
    bottom: 5.5rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
  }
  
  /* Position audio recorder wrapper at the bottom */
  .audio-recorder-wrapper {
    margin-bottom: 0.25rem;
    position: absolute;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
  }

  /* Position the input container properly */
  .chatbot-input-container {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    z-index: 5;
  }
  
  /* Make the input form more compact */
  .chatbot-input {
    padding: 0.5rem 0.75rem;
  }
  
  /* Specific styles for iOS to prevent rubber-banding */
  @supports (-webkit-touch-callout: none) {
    .native-scroll-container {
      /* iOS-specific overscroll prevention */
      -webkit-overflow-scrolling: touch;
      overscroll-behavior-y: none;
      /* Additional iOS tweaks */
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      touch-action: manipulation;
    }
    
    /* Ensure the chat container itself doesn't scroll on iOS */
    .chatbot-section {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      max-height: 100%;
    }
    
    /* Adjust input position for iOS safe areas */
    .chatbot-input-container {
      bottom: calc(0.5rem + env(safe-area-inset-bottom, 0));
      padding-bottom: env(safe-area-inset-bottom, 0);
    }
    
    /* Adjust audio recorder position for iOS safe areas */
    .audio-recorder-wrapper {
      bottom: calc(3.5rem + env(safe-area-inset-bottom, 0));
    }
    
    /* Adjust prompt suggestions position for iOS safe areas */
    .prompt-suggestions-row {
      bottom: calc(5.5rem + env(safe-area-inset-bottom, 0));
    }
  }
  
  .chat-messages {
    padding-bottom: 0.5rem;
    height: auto;
    max-height: none;
  }
  
  .audio-recorder-mobile {
    /* Increase touch target size on mobile */
    width: 56px !important;
    height: 56px !important;
    min-width: 56px;
    min-height: 56px;
  }

  /* Ensure proper spacing around the button */
  .audio-recorder-wrapper {
    padding: 8px 0;
    margin: 0;
  }

  /* Prevent text selection during recording */
  .is-recording {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  /* Optimize the waveform container for mobile */
  .waveformContainer {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    padding: 8px;
    border-radius: 8px;
    z-index: 10;
  }
}

/* Add styles for visual feedback during recording */
.recording-active {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/* Disable selection during processing */
.processing {
  user-select: none;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .chatbot-section {
    touch-action: manipulation; /* Better touch handling */
    -webkit-overflow-scrolling: touch; /* Smooth scroll on iOS */
  }
}

/* Add to your existing ChatModal.css */
.scroll-button-enter {
  opacity: 0;
  transform: translateY(10px);
}

.scroll-button-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.scroll-button-exit {
  opacity: 1;
  transform: translateY(0);
}

.scroll-button-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 200ms, transform 200ms;
}

/* Add these styles */
.loobricate-select-compact {
  font-size: 0.875rem;
  height: 2rem;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-primary);
  cursor: pointer;
  padding-left: 10px;
}

.loobricate-select-compact option {
  padding: 4px;
  background-color: var(--background-secondary);
}

/* Prevent any unwanted touch events */
.audio-recorder-wrapper * {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

/* Optimize for iOS devices */
@supports (-webkit-touch-callout: none) {
  .audio-recorder-mobile {
    /* Add padding for iOS touch targets */
    padding: 12px;
    /* Ensure animation works well on iOS */
    -webkit-animation: subtle-pulse 2s infinite !important;
    animation: subtle-pulse 2s infinite !important;
  }

  /* Fix iOS sticky hover states */
  .audio-recorder-mobile:active {
    transform: scale(0.95);
  }
}

.companion-icon {
  display: flex;
  align-items: flex-end;
  font-size: 1.25rem;
  margin-bottom: 4px;
  opacity: 0.9;
  transition: transform 0.2s ease;
}

.companion-icon:hover {
  transform: scale(1.1);
}

/* Mobile-specific adjustments for chat messages */
@media (max-width: 768px) {
  .chat-messages {
    height: auto; /* Let the positioning determine height */
    max-height: none;
  }
}

/* Add styles for the new audio recorder button */
.audio-recorder-button {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50%;
  background: var(--gradient-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #1a1a1a;
}

.audio-recorder-button:hover {
  transform: scale(1.05);
  background: var(--gradient-user);
}

.audio-recorder-button:active {
  transform: scale(0.95);
}

.audio-recorder-button svg {
  width: 24px;
  height: 24px;
  color: #1a1a1a;
  fill: #1a1a1a;
}

/* Make sure the button doesn't get stuck in active/hover states on mobile */
@media (hover: none) {
  .audio-recorder-button:hover {
    transform: none;
    background: var(--gradient-primary);
  }
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .audio-recorder-button {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }
  
  .audio-recorder-button svg {
    width: 20px;
    height: 20px;
  }
}

/* Add subtle pulse animation for the button */
.audio-recorder-button {
  animation: subtle-pulse 3s infinite;
}

@keyframes subtle-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 148, 148, 0.6);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 0 12px rgba(255, 148, 148, 0.2);
    transform: scale(1.05);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 148, 148, 0);
    transform: scale(1.02);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 148, 148, 0);
    transform: scale(1);
  }
}

/* Add consistent button styling for the send button to match header pill */
.send-button {
  background: var(--gradient-primary);
  color: #1a1a1a;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
}

.send-button:hover {
  opacity: 0.95;
  transform: translateY(-1px);
}

.send-button:active {
  transform: translateY(1px);
  opacity: 1;
}

.send-button:disabled {
  opacity: 0.7;
  background: var(--gradient-primary);
  cursor: not-allowed;
}

/* Add common styling for all gradient buttons */
.gradient-button {
  background: var(--gradient-primary);
  color: #1a1a1a;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Animation for the gradients */
.gradient-button, .send-button, .audio-recorder-button, .audio-recorder-mobile {
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
}

.talk-bubble.user {
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Animation for the gradients - but exclude user message bubbles */
.gradient-button, .send-button, .audio-recorder-button, .audio-recorder-mobile {
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
}

/* Reset the user message bubble animation since we already set it to none */
/* .talk-bubble.user {
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
} */

/* Improved mobile controls wrapper to ensure proper positioning without visual separation */
.mobile-controls-wrapper {
  position: sticky;
  bottom: 0;
  z-index: 20;
  padding-top: 0.5rem;
  padding-bottom: env(safe-area-inset-bottom, 0.5rem);
  border-top: none;
  background-color: var(--background-unified);
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Desktop styles for mobile-controls-wrapper */
@media (min-width: 769px) {
  .mobile-controls-wrapper {
    padding-bottom: 1rem;
    background-color: transparent;
    position: relative;
  }
}

/* Mobile-specific adjustments for the native scroll container */
@media (max-width: 768px) {
  .native-scroll-container {
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    /* Account for the unified control bar */
    max-height: calc(var(--visible-height, 100vh) - 130px);
    transition: max-height 0.15s ease-out;
    position: relative;
    height: auto;
  }
  
  /* Adjust the chat input container */
  .chatbot-input-container {
    background: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    padding: 0.75rem 0.75rem;
    border-radius: 1rem 1rem 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  /* Ensure prompt suggestions are properly positioned */
  .prompt-suggestions-row {
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  
  .prompt-suggestions-row::-webkit-scrollbar {
    display: none;
  }
}

/* Add a ripple effect to the audio recorder button */
.audio-recorder-mobile::after {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  background: rgba(255, 148, 148, 0.3);
  z-index: -2;
  animation: ripple-pulse 2s infinite;
}

@keyframes ripple-pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.3;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

/* Add this class to force re-rendering when needed */
.chat-modal-container.initialized {
  opacity: 1;
  transform: translateZ(0); /* Force hardware acceleration */
  will-change: transform; /* Hint to browser to optimize */
}

/* iPhone X and newer - account for the notch and home indicator */
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
       screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
       screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .chat-modal-container {
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .chatbot-section {
    padding-bottom: calc(3rem + env(safe-area-inset-bottom));
  }
  
  .chat-input-container {
    padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));
  }
}

/* Add these styles to integrate prompt suggestions better with the chat interface */
.prompt-suggestions-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 0.25rem;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: transparent; /* Ensure it blends with parent */
}

.prompt-suggestion {
  background-color: rgba(45, 48, 63, 0.5) !important; /* Match the input background */
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 1rem !important; /* Match input roundness */
  padding: 0.5rem 1rem !important;
  font-size: 0.9rem !important;
  color: var(--text-primary) !important;
  transition: all 0.2s ease !important;
  backdrop-filter: blur(4px) !important; /* Match input style */
  box-shadow: none !important;
}

.prompt-suggestion:hover, 
.prompt-suggestion:focus {
  background-color: rgba(60, 65, 85, 0.6) !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
}

/* Ensure the chat container has a unified feel with padding adjustments */
@media (min-width: 768px) {
  .chat-modal-container {
    background-image: radial-gradient(circle at center, rgba(35, 38, 55, 0.6) 0%, rgba(20, 22, 35, 0.9) 100%);
  }
  
  /* Adjust the box shadow on desktop for a more elegant look */
  .chatbot-section {
    box-shadow: 
      0 10px 25px -5px rgba(0, 0, 0, 0.4),
      0 5px 10px -5px rgba(0, 0, 0, 0.3),
      0 0 0 1px rgba(255, 255, 255, 0.08);
  }
}

/* Add specific styles for devices with notches or special screen areas */
@supports (padding: max(0px)) {
  .mobile-controls-wrapper {
    padding-bottom: max(0.5rem, env(safe-area-inset-bottom, 0.5rem));
  }
  
  .chat-modal-container {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
}

/* Specific adjustments for when virtual keyboard is active */
@media (max-height: 600px) {
  .native-scroll-container {
    /* Reduce message area when keyboard is visible to make room for input */
    max-height: calc(var(--visible-height, 100vh) - 120px);
  }
  
  .prompt-suggestions-row {
    /* Hide suggestions when space is limited */
    display: none;
  }
  
  .mobile-controls-wrapper {
    /* Ensure controls float on top of content */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-unified);
    z-index: 1000;
  }
}

/* Fix for iOS devices to properly handle virtual keyboard */
@media screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .chat-modal-container, 
  .chatbot-section {
    height: calc(var(--visible-height, 100vh));
  }
  
  .chatbot-input {
    /* Ensure input is visible and tappable */
    font-size: 16px !important; /* Prevents iOS zoom on focus */
  }
}

/* Mobile-specific adjustments for the scroll container */
@media (max-width: 768px) {
  .native-scroll-container {
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    max-height: calc(100vh - 160px); /* Account for header, input, and bottom controls */
  }
}

/* Mobile-specific adjustments for the scroll container */
@media (max-width: 768px) {
  .native-scroll-container {
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    max-height: calc(var(--visible-height, 100vh) - 160px); /* Account for header, input, and bottom controls */
    transition: max-height 0.15s ease-out;
    flex: 1 1 auto;
    position: relative;
    height: auto;
  }
  
  /* Adjust when virtual keyboard is present */
  .chatbot-input-container {
    background: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    padding: 0.5rem 0.75rem;
    transition: padding 0.15s ease;
  }
}

/* When virtual keyboard appears */
@media (max-height: 500px) {
  .native-scroll-container {
    max-height: calc(var(--visible-height, 100vh) - 120px);
    padding-bottom: 0.5rem;
  }
}

/* Inline audio recorder button specifically for the unified input bar */
.audio-recorder-button-inline {
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  background: var(--gradient-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #1a1a1a;
}

.audio-recorder-button-inline:hover {
  transform: scale(1.05);
  background: var(--gradient-user);
}

.audio-recorder-button-inline:active {
  transform: scale(0.95);
}

.audio-recorder-button-inline svg {
  width: 20px;
  height: 20px;
  color: #1a1a1a;
  fill: #1a1a1a;
}

/* Add animation to make it look nice */
.audio-recorder-button-inline {
  animation: subtle-pulse 3s infinite;
  background-size: 400% 400%;
}

/* Mobile optimizations for the new audio button */
@media (max-width: 640px) {
  .audio-recorder-button-inline {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
  
  .audio-recorder-button-inline svg {
    width: 18px;
    height: 18px;
  }
}

/* Add these styles for the new prompt-suggestions-container */
.prompt-suggestions-container {
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

@media (max-width: 768px) {
  .prompt-suggestions-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    padding-bottom: 0.25rem;
  }
  
  .prompt-suggestions-container::-webkit-scrollbar {
    display: none;
  }
}

/* Update the prompt-suggestions-row for better horizontal scrolling on mobile */
@media (max-width: 768px) {
  .prompt-suggestions-row {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    width: auto;
    overflow-x: auto;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
  
  /* Adjust spacing when virtual keyboard is visible */
  @media (max-height: 500px) {
    .prompt-suggestions-container {
      margin-bottom: 0.25rem;
    }
  }
}

/* Enhanced input field for a more native app-like appearance */
.chatbot-input {
  background: rgba(42, 43, 54, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.2s ease;
  border-radius: 1.25rem;
  height: 46px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.chatbot-input:focus {
  border-color: rgba(255, 105, 180, 0.5);
  box-shadow: 0 0 0 2px rgba(255, 105, 180, 0.1);
  background: rgba(50, 51, 64, 0.9);
}

/* Enhanced input container for mobile devices */
@media (max-width: 768px) {
  .chatbot-input-container {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.75rem 0.75rem;
    z-index: 50;
    background-color: rgba(26, 27, 39, 0.95);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    /* Add subtle shadow for depth */
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    /* Add safe area inset padding */
    padding-bottom: max(0.75rem, env(safe-area-inset-bottom));
  }
  
  /* Slightly smaller input on mobile */
  .chatbot-input {
    height: 42px;
    font-size: 16px !important; /* Prevents iOS zoom */
  }
}

/* Enhanced Immersive Recorder Styles */
.enhanced-recorder-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 10, 15, 0.75);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.2s ease-out;
}

/* Horizontal layout for options */
.options-horizontal-container {
  position: relative;
  bottom: auto;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 20px;
  z-index: 1001;
}

.option-card {
  background-color: rgba(25, 25, 35, 0.85);
  border: 1px solid rgba(230, 179, 204, 0.2);
  border-radius: 16px;
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  transition: all 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.option-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(230, 179, 204, 0.08), transparent 70%);
  opacity: 0.5;
  transition: opacity 0.25s ease;
}

.option-card:hover, .option-card:focus {
  transform: translateY(-5px);
  border-color: rgba(230, 179, 204, 0.4);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.25), 0 0 15px rgba(230, 179, 204, 0.2);
}

.option-card:hover::before, .option-card:focus::before {
  opacity: 1;
}

.option-card:active {
  transform: translateY(0);
}

.option-icon {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.option-card:hover .option-icon {
  transform: scale(1.1);
}

.option-card h3 {
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
  line-height: 1.3;
}

/* Close button */
.close-overlay-button {
  position: absolute;
  top: -60px;
  right: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(25, 25, 35, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.close-overlay-button.floating {
  position: absolute;
  top: -50px;
  right: 16px;
}

.close-overlay-button:hover {
  background-color: rgba(35, 35, 45, 0.95);
  color: white;
  transform: scale(1.05);
}

.close-overlay-button span {
  line-height: 1;
  transform: translateY(-1px);
}

/* Original container styles (for other phases) */
.enhanced-recorder-container {
  background-color: #1a1b26;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 480px;
  max-height: 85vh;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: slideUp 0.4s ease-out forwards;
  transform: translateY(20px);
  opacity: 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  to { 
    transform: translateY(0); 
    opacity: 1;
  }
}

/* Header styles */
.recorder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.recorder-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(to right, #e8b4b8, #ffceb3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Options container */
.options-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.options-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin-bottom: 8px;
  text-align: center;
}

.option-button {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  background-color: rgba(255, 148, 148, 0.1);
  border: 1px solid rgba(255, 148, 148, 0.2);
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  gap: 16px;
}

.option-button:hover, .option-button:focus {
  background-color: rgba(255, 148, 148, 0.15);
  border-color: rgba(255, 148, 148, 0.3);
  transform: translateY(-2px);
}

.option-button:active {
  transform: translateY(0);
}

.option-text {
  flex: 1;
}

.option-text h3 {
  margin: 0 0 4px 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.option-text p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Processing content */
.processing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  gap: 16px;
  text-align: center;
}

.processing-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid rgba(255, 148, 148, 0.1);
  border-top-color: rgba(255, 148, 148, 0.7);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.processing-content h3 {
  margin: 8px 0 0 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
}

.processing-content p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Confirmation content */
.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 24px;
  text-align: center;
}

.confirmation-icon {
  font-size: 2.5rem;
  margin-bottom: 8px;
  animation: pulseScale 1.5s ease-in-out;
}

@keyframes pulseScale {
  0% { transform: scale(0.5); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

.memory-card {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(255, 148, 148, 0.15);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.memory-type {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgba(255, 148, 148, 0.8);
}

.memory-text {
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  word-break: break-word;
  white-space: pre-wrap;
}

.memory-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}

.keyword-tag {
  font-size: 0.75rem;
  background-color: rgba(255, 148, 148, 0.15);
  color: rgba(255, 148, 148, 0.9);
  padding: 4px 8px;
  border-radius: 50px;
  white-space: nowrap;
}

.confirmation-message {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 90%;
  margin: 0;
}

.done-button {
  background: linear-gradient(to right, rgba(255, 148, 148, 0.7), rgba(255, 194, 179, 0.7));
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 32px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 10px rgba(255, 148, 148, 0.2);
}

.done-button:hover {
  background: linear-gradient(to right, rgba(255, 148, 148, 0.8), rgba(255, 194, 179, 0.8));
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 148, 148, 0.3);
}

.done-button:active {
  transform: translateY(0);
}

/* Error content */
.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  gap: 16px;
  text-align: center;
}

.error-icon {
  font-size: 2rem;
  color: rgba(255, 82, 82, 0.8);
  margin-bottom: 8px;
}

.error-content h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 82, 82, 0.9);
}

.error-content p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 90%;
}

.try-again-button {
  background-color: rgba(255, 82, 82, 0.15);
  color: rgba(255, 82, 82, 0.9);
  border: 1px solid rgba(255, 82, 82, 0.3);
  border-radius: 50px;
  padding: 10px 24px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.try-again-button:hover {
  background-color: rgba(255, 82, 82, 0.25);
}

/* Media queries for mobile and tablet */
@media (max-width: 480px) {
  .options-horizontal-container {
    flex-direction: row;
    padding: 12px;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    bottom: auto;
  }
  
  .option-card {
    width: 100px;
    height: 100px;
    padding: 8px;
  }
  
  .option-icon {
    font-size: 1.6rem;
  }
  
  .option-card h3 {
    font-size: 0.75rem;
  }
  
  .close-overlay-button.floating {
    top: 16px;
    right: 16px;
  }
}

@media (max-width: 350px) {
  .options-horizontal-container {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 16px;
  }
  
  .option-card {
    width: 90%;
    max-width: 140px;
    height: 80px;
    flex-direction: row;
    justify-content: flex-start;
    padding: 8px 16px;
  }
  
  .option-icon {
    font-size: 1.5rem;
    margin-right: 12px;
  }
  
  .option-card h3 {
    font-size: 0.85rem;
    text-align: left;
  }
}

/* Add styles for the modal floating card used in all phases except options */
.modal-floating-card {
  background-color: rgba(25, 25, 35, 0.9);
  border: 1px solid rgba(230, 179, 204, 0.2);
  border-radius: 18px;
  width: 90%;
  max-width: 380px;
  padding: 24px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.3);
  animation: floatIn 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) forwards;
  position: relative;
  overflow: hidden;
}

.modal-floating-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(230, 179, 204, 0.08), transparent 70%);
  opacity: 0.5;
  pointer-events: none;
}

@keyframes floatIn {
  0% { 
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  100% { 
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Update recorder header */
.recorder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.recorder-header h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  background: linear-gradient(to right, rgba(230, 179, 204, 0.95), rgba(255, 194, 179, 0.95));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Close button small variant */
.close-overlay-button.small {
  position: relative;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  font-size: 1.3rem;
}

/* Processing content - updated for minimal design */
.processing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  gap: 20px;
  text-align: center;
}

.processing-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid rgba(230, 179, 204, 0.1);
  border-top-color: rgba(230, 179, 204, 0.7);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.processing-content h3 {
  margin: 0;
  font-size: 1.15rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

/* Confirmation content - updated for sleek design */
.confirmation-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.memory-preview {
  background-color: rgba(20, 20, 30, 0.8);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(230, 179, 204, 0.15);
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.memory-type {
  font-size: 0.9rem;
  font-weight: 500;
  color: rgba(230, 179, 204, 0.8);
}

.memory-text {
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  word-break: break-word;
  white-space: pre-wrap;
}

.confirmation-actions {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 8px;
}

.action-button {
  flex: 1;
  padding: 12px;
  border-radius: 12px;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button.confirm {
  background: linear-gradient(to right, rgba(230, 179, 204, 0.8), rgba(255, 194, 179, 0.8));
  color: rgba(20, 20, 30, 0.9);
}

.action-button.confirm:hover {
  background: linear-gradient(to right, rgba(230, 179, 204, 0.9), rgba(255, 194, 179, 0.9));
  transform: translateY(-2px);
}

.action-button.retry {
  background-color: rgba(230, 179, 204, 0.1);
  color: rgba(230, 179, 204, 0.9);
  border: 1px solid rgba(230, 179, 204, 0.2);
}

.action-button.retry:hover {
  background-color: rgba(230, 179, 204, 0.15);
  border-color: rgba(230, 179, 204, 0.3);
  transform: translateY(-2px);
}

.action-button:active {
  transform: translateY(0);
}

/* Error content - updated for sleek design */
.error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 20px;
  text-align: center;
}

.error-icon {
  font-size: 2rem;
  color: rgba(255, 82, 82, 0.8);
}

.error-content p {
  margin: 0;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
}

.error-card .action-button.retry {
  background-color: rgba(255, 82, 82, 0.1);
  color: rgba(255, 82, 82, 0.9);
  border: 1px solid rgba(255, 82, 82, 0.2);
  margin-top: 8px;
  width: 140px;
}

.error-card .action-button.retry:hover {
  background-color: rgba(255, 82, 82, 0.15);
  border-color: rgba(255, 82, 82, 0.3);
}

/* Mobile optimizations for modal cards */
@media (max-width: 480px) {
  .modal-floating-card {
    padding: 20px;
    max-width: 340px;
  }
  
  .recorder-header h2 {
    font-size: 1.2rem;
  }
  
  .memory-preview {
    padding: 14px;
  }
  
  .action-button {
    padding: 10px;
    font-size: 0.9rem;
  }
}

/* Recording phase styles */
.enhanced-recorder-overlay.recording-phase {
  align-items: flex-start;
  justify-content: flex-start;
}

.recording-close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1010;
  background-color: rgba(20, 20, 30, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
}

.enhanced-recorder-integration {
  /* Override ImmersiveAudioRecorder styles to match our design */
  width: 100%;
  height: 100%;
}

.recording-type-indicator {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(20, 20, 30, 0.8);
  border: 1px solid rgba(230, 179, 204, 0.2);
  border-radius: 50px;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 1010;
  white-space: nowrap;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: fadeIn 0.3s ease-out;
}

/* Mobile optimizations for recording phase */
@media (max-width: 480px) {
  .recording-close {
    top: 16px;
    right: 16px;
  }
  
  .recording-type-indicator {
    bottom: 80px;
    font-size: 0.9rem;
    padding: 8px 16px;
  }
} 